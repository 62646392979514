import { useOnScreen } from "./App.jsx";

export default function Footer() {
  const [ref, visible] = useOnScreen({ rootMargin: "-30px" });
  return (
    <footer
      ref={ref}
      className={
        visible
          ? "element h-screen scroll-show"
          : "element h-screen scroll-hide"
      }
    >
      <p className="text-white dark:text-gray-454545 dark:bg-white">Copyright © 2016-{new Date().getFullYear()} <a href="https://bloganueva.com" target="_blank" title="Bloganueva" rel="noreferrer">Bloganueva</a> | <a href="mailto:marshall.villanueva@gmail.com" rel="noreferrer">Contact Me</a></p>
    </footer>
  );
}
