import { useOnScreen } from "./App.jsx";

export default function Profile() {
  const [ref, visible] = useOnScreen({ rootMargin: "-50px" });
  return (
    <div
      ref={ref}
      className={
        visible
          ? "element h-screen block profile scroll-show"
          : "element h-screen block profile scroll-hide"
      }
    >
      <div id="" className="dark:bg-white">
          <img
            alt="Bloganueva Logo"
            src={"https://bloganueva.com/images/self-headshot-2-square.jpg"}
            style={{boxShadow: "0 0 20px rgb(0 0 0 / 50%)"}}
          />
          <hr className="dark:bg-gray-454545" />
        <p className="dark:text-gray-454545">I'm Marshall. I'm the Senior Web Developer at American Tradeshow Services. I’ve been a professional developer for over 7 years now. I’ve learned so much in this short amount of time. I've gained a lot of web development experience. I’ve handled web security and maintenance issues. I’ve assisted in developing a few mobile apps for Android and iOS. I've done system administration. I’ve managed databases and utilized them to generate reports. I’ve updated the back ends of spreadsheets. I've combined different technologies to provide unique solutions. I’ve gained some great full-stack experience and strive to continually acquire new skills.</p>
      </div>
    </div>
  );
}