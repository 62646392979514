import "./App.css";
import Header from "./Header.jsx";
import Project from "./Project.jsx";
import Profile from "./Profile.jsx";
import Experience from "./Experience.jsx";
import Skills from "./Skills.jsx";
import ExperienceWith from "./ExperienceWith";
import Footer from "./Footer.jsx";
import LavarvelAPI from "./LaravelAPI.jsx";
import React from "react";
import { ThemeProvider } from "./themeContext";
import Toggle from "./themeToggle";

export function useOnScreen(options) {
  const ref = React.useRef();
  const [visible, setVisible] = React.useState(false);

  // Code to detect if an element is in view
  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);
  return [ref, visible];
}

function App() {
  return (
    <ThemeProvider>
      <div className="relative justify-center h-screen w-full dark:bg-gray-454545">
        <div className="container y-scroll y-mandatory w-full h-screen">
          <div className="absolute right-0 top-0 mr-4 mt-4 md:mr-6 md:mt-6">
            <Toggle />
          </div>
          <Header></Header>

          <div>
            <Project description={<LavarvelAPI />} />
            <Profile></Profile>
            <Experience></Experience>
            <Skills></Skills>
            <ExperienceWith></ExperienceWith>
            <Project
              title={"Bloganueva"}
              link={"/bloganueva.png"}
              url={"https://bloganueva.com/"}
              linkText={"View Website"}
              description={"Laravel and React"}
              name={"first"}
            />
            <Project
              title={"Expressions of America"}
              link={"/expressionsofamerica.png"}
              url={"https://www.expressionsofamerica.org/"}
              linkText={"View Website"}
              description={"WordPress"}
            />
            <Project
              title={"“EVERYTHING WE HAVE”"}
              link={"/ddaybook.png"}
              url={"https://www.ddaybook.com/"}
              linkText={"View Website"}
              description={"HTML, CSS, and jQuery"}
            />
            <Project
              title={"Drafts for Crafts"}
              link={"/draftsforcrafts.png"}
              url={"https://draftsforcrafts.org/"}
              linkText={"View Website"}
              description={"HTML, CSS, and jQuery"}
            />
            <Project
              title={"Christopher Freeman"}
              link={"/purpleflapjack.png"}
              url={"https://www.purpleflapjack.com/"}
              linkText={"View Website"}
              description={"HTML, CSS, and jQuery"}
            />
            <Project
              title={"WWII Air, Sea & Land Festival"}
              link={"/airsealandfest.png"}
              url={"https://airsealandfest.com/"}
              linkText={"View Website"}
              description={"HTML, CSS, and jQuery"}
            />
          </div>
          <Footer></Footer>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
