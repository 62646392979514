import { useOnScreen } from "./App.jsx";

export default function Experience() {
  const [ref, visible] = useOnScreen({ rootMargin: "-50px" });
  return (
    <div
      ref={ref}
      className={
        visible
          ? "element h-screen block experience scroll-show"
          : "element h-screen block experience scroll-hide"
      }
    >
      <div id="" className="dark:bg-white">
        <p className="dark:text-gray-454545">
          <strong style={{fontSize: "1.55em", marginBottom: "1rem"}}>EXPERIENCE</strong>
          <hr className="dark:bg-gray-454545" />
          <br className="special"/>
          <strong><u>Senior Web Developer, American Tradeshow Services</u></strong> Mandeville
          <br />FEBRUARY 2023 - PRESENT
          <br />
          I create special projects and use APIs to provide proprietary services.
          <br />
          <br />
          <strong><u>Senior Web Manager, The National WWII Museum</u></strong> New Orleans
          <br />AUGUST 2017 - PRESENT
          <br />
          I develop and maintain websites while also working on various
          projects.
          <br />
          <br />
          <strong><u>Web Developer, IJM Consulting Group</u></strong> New Orleans
          <br />
          MARCH 2017 - AUGUST 2017
          <br />
          I created custom WordPress templates for Louisiana Running Company.
          <br />
          <br />
          <strong><u>Software Developer, Verma Systems Inc.</u></strong> Baton Rouge
          <br />
          DECEMBER 2015 - FEBRUARY 2017
          <br />I generated approximately $150,000 in revenue by building
          websites, developing mobile apps, managing databases, and generating
          reports.
        </p>
      </div>
    </div>
  );
}