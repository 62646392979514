import { useOnScreen } from "./App.jsx";

export default function Skills() {
  const [ref, visible] = useOnScreen({ rootMargin: "-50px" });
  return (
    <div
      ref={ref}
      className={
        visible
          ? "element h-screen block scroll-show skills"
          : "element h-screen block scroll-hide skills"
      }
    >
      <div id="" className="dark:bg-white">
        <p className="dark:text-gray-454545"><strong style={{fontSize: "1.25em", marginBottom: "1rem"}}>SKILLS</strong></p>
        <hr className="dark:bg-gray-454545" />
        <p className="dark:text-gray-454545" style={{textAlign: "center"}}>Laravel • Javascript • SQL • Vue.js • ReactJS • PHP • Linode • Drupal • WordPress • MVC • JQuery • Git • C++ • Xcode</p>
      </div>
    </div>
  );
}