import React, { useState, useEffect } from "react";
import axios from "axios";

const LaravelAPI = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetchPosts();
  }, []);

  // Get data from API
  const fetchPosts = async () => {
    const response = await axios("https://bloganueva.com/api/workanueva", {
      method: 'GET',
      headers: {
        'Content-Type':'application/json',
        Accept: 'application/json'
      }
    });
    setPosts(response.data);
  };
  return (
    <span>
      {posts &&
        posts.map((post) => {
          return <span key={post.id}>{post.body}</span>;
        })}
    </span>
  );
};

export default LaravelAPI;