import { useOnScreen } from "./App.jsx";

const Project = ({ title, link, url, linkText, description }) => {
  const [ref, visible] = useOnScreen({ rootMargin: "-50px" });
  
  return (
    <div
      ref={ref}
      className={
        visible
          ? "element h-screen block scroll-show"
          : "element h-screen block scroll-hide"
      }
    >
      <div
        className="dark:bg-white"
      >
        <img src={link} alt={title} />
        <h2 className="dark:text-gray-454545">{title}</h2>
        <p><a rel="noreferrer" target="_blank" className="dark:bg-gray-454545 dark:text-white" href={url}>{linkText}</a></p>
        <p className="dark:text-gray-454545" >{description}</p>
      </div>
    </div>
  );
};

export default Project;
