import { useOnScreen } from "./App.jsx";
import { getInitialTheme } from "./themeContext.jsx";

export default function Header() {
  const [ref, visible] = useOnScreen({ rootMargin: "-30px" });
  return (
    <header
      ref={ref}
      className={
        visible
          ? "h-screen w-full element flex m-auto scroll-show"
          : "h-screen w-full element flex m-auto scroll-hide"
      }
    >
      <div id="header" className="dark:bg-white">
        <a
          rel="noreferrer"
          href="https://bloganueva.com/"
          target="_blank"
          title="Bloganueva"
        >
          <img
            id="myImg"
            alt="Bloganueva Logo"
            src={
              getInitialTheme() === "dark"
                ? "./bloganueva-logo-gray.png"
                : "./bloganueva-logo.png"
            }
          />
        </a>
        <h1 className="text-center dark:text-gray-454545">&nbsp;Workanueva</h1>
      </div>
      <div
      style={{
        position: "absolute",
        bottom: "4em",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: "auto",
        boxShadow: "none",
        background: "none",
        display: "flex"
      }}
    >
      <i style={{
        fontSize: "20px",
        padding: "0",
        background: "none",
        verticalAlign: "top"
      }} 
      id="next"
      className="animate-bounce temporary-bounce dark:bg-gray-454545 dark:text-white fas fa-chevron-down"></i>
    </div>
    </header>
  );
}
