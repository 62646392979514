import React from "react";
import { ThemeContext } from './themeContext';
import { IoToggle } from "react-icons/io5";

function whiteLogo(){
  document.getElementById("myImg").src = "/bloganueva-logo.png";
}
function grayLogo(){
  document.getElementById("myImg").src = "/bloganueva-logo-gray.png";
}

const Toggle = () => {
  const { theme, setTheme } = React.useContext(ThemeContext);
  return (
    <div className="transition duration-500 ease-in-out rounded-full p-2">
      {theme === "dark" ? (
        // if dark, want switch to get flicked on
        <IoToggle
          onClick={() => {whiteLogo(); setTheme(theme === "dark" ? "light" : "dark");}}
          className="text-gray-454545 dark:text-white text-2xl cursor-pointer"
        />
      ) : (
        // if light, want switch to get flicked off
        <IoToggle
          onClick={() => {grayLogo(); setTheme(theme === "dark" ? "light" : "dark");}}
          className="text-gray-454545 text-2xl cursor-pointer light-switch"
        />
      )}
    </div>
  );
};
export default Toggle;