import { useOnScreen } from "./App.jsx";

export default function ExperienceWith() {
  const [ref, visible] = useOnScreen({ rootMargin: "-50px" });
  return (
    <div
      ref={ref}
      className={
        visible
          ? "element h-screen block scroll-show experiencewith"
          : "element h-screen block scroll-hide experiencewith"
      }
    >
      <div id="" className="dark:bg-white">
        <p className="dark:text-gray-454545"><strong style={{fontSize: "1.25em", marginBottom: "1rem"}}>FAMILIAR WITH</strong></p>
        <hr className="dark:bg-gray-454545" />
        <p className="dark:text-gray-454545" style={{textAlign: "center"}}>Google Analytics • Google Tag Manager • Firebase • Sucuri • Ionic Framework • Java • Xamarin • Python • AngularJS</p>
      </div>
    </div>
  );
}